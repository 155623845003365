import React from "react";
import Layout from "../components/_App/layout";
import SEO from "../components/_App/seo"; 
import Navbar from "../components/_App/Navbar";
import Footer from "../components/_App/Footer";
import PageBanner from '../components/Common/PageBanner'; 
import * as Icon from 'react-feather';
import { Link } from "gatsby"; 




const BlogDetails = () => (
    <Layout>
        <SEO title="Protection des données personnelles" /> 

        <Navbar />

        <PageBanner pageTitle="Protection des données personnelles" /> 

        <div className="blog-details-area ptb-80">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="blog-details-desc">

                            <div className="article-content">
                                <div className="entry-meta">
                                    <ul>
                                        <li>
                                            <Icon.Clock /> Dernière mise à jour le 23 mars 2023
                                        </li>
                                    </ul>
                                </div>

                                <h2>Protection des données personnelles</h2> 

                                <p>Vos données sont collectées par l’entreprise individuelle Lucas TRONCY.</p>
                                <p>Une donnée à caractère personnel désigne toute information concernant une personne physique identifiée ou identifiable (personne concernée) ; est réputée identifiable une personne qui peut être identifiée, directement ou indirectement, notamment par référence à un nom, un numéro d’identification ou à un ou plusieurs éléments spécifiques, propres à son identité physique, physiologique, génétique, psychique, économique, culturelle ou sociale.</p>
                                <p>Les informations personnelles pouvant être recueillies sur le site sont principalement utilisées par l’éditeur pour la gestion des relations avec vous, et le cas échéant pour le traitement de vos commandes.</p>
                                <p>Les données personnelles collectées sont les suivantes : Nom ; prénom ; date de naissance, adresse électronique, téléphone, adresse, pays, sexe. Nous ne stockons auncune donnée bancaire !</p>
                                <p>Pour la souscription à un service gratuit (étudiants seulement), nous collectons une pièce d'identité et un certificat de scolarité pour la mise en relation. Néanmoins, une fois la validation de ces deux documents effectuée, nous ne les conservons pas.</p>
                                
                                <h2>Droit d’accès, de rectification et de déréférencement de vos données</h2>
                                <p>En application de la réglementation applicable aux données à caractère personnel, les utilisateurs disposent des droits suivants :</p>
                                <ul>
                                    <li>le droit d’accès : ils peuvent exercer leur droit d’accès, pour connaître les données personnelles les concernant, en écrivant à l’adresse électronique ci-dessous mentionnée. Dans ce cas, avant la mise en œuvre de ce droit, la Plateforme peut demander une preuve de l’identité de l’utilisateur afin d’en vérifier l’exactitude ;</li>
                                    <li>le droit de rectification : si les données à caractère personnel détenues par la Plateforme sont inexactes, ils peuvent demander la mise à jour des informations ;</li>
                                    <li>le droit de suppression des données : les utilisateurs peuvent demander la suppression de leurs données à caractère personnel, conformément aux lois applicables en matière de protection des données ;</li>
                                    <li>le droit à la limitation du traitement : les utilisateurs peuvent de demander à la Plateforme de limiter le traitement des données personnelles conformément aux hypothèses prévues par le RGPD ;</li>
                                    <li>le droit de s’opposer au traitement des données : les utilisateurs peuvent s’opposer à ce que leurs données soient traitées conformément aux hypothèses prévues par le RGPD ;</li>
                                    <li>le droit à la portabilité : ils peuvent réclamer que la Plateforme leur remette les données personnelles qu’ils ont fournies pour les transmettre à une nouvelle Plateforme.</li>
                                </ul>
                                <p>Vous pouvez exercer ces droits en nous contactant, à l’adresse suivante :</p>
                                <a href="mailto:contact@fenuahosting.com">contact@fenuahosting.com</a>
                                <p>Toute demande doit être accompagnée de la photocopie d’un titre d’identité en cours de validité signé et faire mention de l’adresse à laquelle l’éditeur pourra contacter le demandeur. La réponse sera adressée dans le mois suivant la réception de la demande. Ce délai d’un mois peut être prolongé de deux mois si la complexité de la demande et/ou le nombre de demandes l’exigent.</p>

                                <h2>Utilisation des données</h2>
                                <p>Les données personnelles collectées auprès des utilisateurs ont pour objectif la mise à disposition des services de la Plateforme, leur amélioration et le maintien d’un environnement sécurisé. La base légale des traitements est l’exécution du contrat entre l’utilisateur et la Plateforme. Plus précisément, les utilisations sont les suivantes :</p>
                                <ul>
                                    <li>accès et utilisation de la Plateforme par l’utilisateur ;</li>
                                    <li>gestion du fonctionnement et optimisation de la Plateforme ; </li>
                                    <li>mise en œuvre d’une assistance utilisateurs ;</li>
                                    <li>vérification, identification et authentification des données transmises par l’utilisateur ; </li>
                                    <li>personnalisation des services en affichant des publicités en fonction de l’historique de navigation de l’utilisateur, selon ses préférences ;</li>
                                    <li>prévention et détection des fraudes, malwares (malicious softwares ou logiciels malveillants) et gestion des incidents de sécurité ;</li>
                                    <li>gestion des éventuels de litiges avec les utilisateurs ;</li>
                                </ul>

                                <h2>Cookies</h2>
                                <p>Nous n'utilisons aucun cookies ! Pas de tracking ni de Pub ! FenuaHosting respecte votre vie privée !</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <Footer />
    </Layout>
)

export default BlogDetails;